var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState, } from 'react';
import Tooltip from '@shared/ui/tooltips/Tooltip';
const withTextEllipsis = (TypographyComponent) => {
    const TypographyWithTooltip = (_a) => {
        var { tooltipText } = _a, props = __rest(_a, ["tooltipText"]);
        const typographyRef = useRef(null);
        const [isEllipsisActive, setIsEllipsisActive] = useState(false);
        useEffect(() => {
            const container = typographyRef.current;
            if (container) {
                if (container.scrollWidth > container.clientWidth) {
                    setIsEllipsisActive(true);
                }
            }
        }, []);
        // TODO: control tooltip via open and onOpen?
        // TODO: think about a way how to get the user to customize both tooltip and
        // typography from outside
        return (_jsx(Tooltip, { title: isEllipsisActive ? tooltipText : '', placement: 'top', arrow: true, children: _jsx(TypographyComponent, Object.assign({ ref: typographyRef, noWrap: tooltipText !== '' }, props)) }));
    };
    return TypographyWithTooltip;
};
export default withTextEllipsis;
